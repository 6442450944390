@charset "UTF-8";

/* contents.css */

// # 非ロード時
// ------------------------------
#overlay {
	display:none;
}


// # ロード後
// ------------------------------
html.load_complete {

	.module_categories_v2 {
		visibility: visible;
	}
}


#container {
	.inner {
		width: 100%;
		max-width: 950px;
		margin: 0 auto;
		padding: 0 20px;
		box-sizing: border-box;
	}
	#content {
		padding-bottom: 50px;
		background-color: #ffffff;
		.label {
			display: inline-block;
			position: relative;
			margin-top: 4px;
			margin-left: 20px;
			padding: 0 4px;
			vertical-align: top;
			line-height: 20px;
			color: #999999;
			font-size: 12px;
			font-size: 1.2rem;
			letter-spacing: 0;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				left: -10px;
				width: 0;
				height: 0;
				border-width: 10px 5px;
				border-style: solid;
				border-color: transparent;
			}
			&.sponsored {
				color: #666666;
				background-color: #fff200;
				&:before {
					border-right-color: #fff200;
				}
			}
		}
		#list_header {
			padding-bottom: 30px;
			background-color: #111111;
			.inner {
				h2 {
					text-align: center;
					color: #999999;
					font-size: 18px;
					font-size: 1.8rem;
				}
				.btn,
				.profile {
					float: left;
				}
				.btn {
					.photo,
					.txt {
						display: table-cell;
						vertical-align: middle;
					}
				}
				.profile {
					.photo,
					.txt {
						display: table-cell;
						vertical-align: middle;
					}
				}
				.btn .photo,
				.profile .photo {
					width: 105px;
					height: 105px;
					padding-bottom: 0;
				}
				.btn .txt,
				.profile .txt {
					padding-left: 20px;
					font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", sans-serif;
				}
				.btn .txt dt,
				.profile .txt dt {
					color: #ffffff;
					font-size: 22px;
					font-size: 2.2rem;
					font-weight: bold;
					letter-spacing: -0.02em;
				}
				.btn .txt dt span,
				.profile .txt dt span {
					margin-left: 15px;
					color: #999999;
					font-size: 16px;
					font-size: 1.6rem;
				}
				.btn .txt dd {
					margin-top: 10px;
					color: #cccccc;
					font-size: 16px;
					font-size: 1.6rem;
				}
				.profile {
					.txt dd {
						margin-top: 10px;
						color: #cccccc;
						font-size: 16px;
						font-size: 1.6rem;
					}
					.description {
						margin-top: 15px;
						line-height: 1.6;
						color: #ffffff;
						font-size: 16px;
						font-size: 1.6rem;
						dl {
							dt {
								display: table-cell;
							}
							dd {
								display: table-cell;
								a {
									color: #ffffff;
									border-bottom: 1px dotted #ffffff;
									&:link,
									&:visited {
										color: #ffffff;
										border-bottom: 1px dotted #ffffff;
									}
									&:hover,
									&:focus,
									&:active {
										color: #999999;
										border-bottom-color: #999999;
									}
								}
							}
						}
					}
				}
				.btn {
					margin-bottom: 20px;
					a {
						margin-bottom: 0;
					}
					&.category {
						padding-right: 100px;
						.photo .icon {
							position: relative;
							width: 100%;
							height: 100%;
							text-align: center;
							background-color: #333333;
							// border-radius: 50%;
							img {
								position: absolute;
								top: 50%;
								left: 50%;
								width: 74px;
								height: 74px;
								margin-top: -37px;
								margin-left: -37px;
							}
						}
					}
					&.member {
						max-width: 50%;
					}
				}
			}
			&.type_2 .inner {
				.btn .photo,
				.profile .photo {
					width: 60px;
					height: 60px;
				}
				.btn .txt,
				.profile .txt {
					padding-left: 10px;
					dt {
						font-size: 18px;
						font-size: 1.8rem;
						font-weight: normal;
					}
				}
				.btn.category {
					position: relative;
					padding-right: 40px;
					&:after {
						content: "✕";
						position: absolute;
						top: 0;
						right: 15px;
						line-height: 60px;
						color: #999999;
						font-size: 12px;
						font-size: 1.2rem;
					}
					&:last-child:after {
						content: "by";
						right: 15px;
					}
					.photo .icon img {
						width: 40px;
						height: 40px;
						margin-top: -20px;
						margin-left: -20px;
					}
				}
			}
		}
		#nav_next_prev {
			padding: 25px 0;
			background-color: #f5f5f5;
			.inner ul {
				position: relative;
				text-align: center;
				li {
					position: relative;
					white-space: nowrap;
					line-height: 30px;
					font-size: 22px;
					font-size: 2.2rem;
					letter-spacing: -0.02em;
					z-index: 20;
					dl {
						position: absolute;
						top: 100px;
						width: 105px;
						&:before {
							content: "";
							display: block;
							position: absolute;
							top: -45px;
							left: 50%;
							width: 0;
							height: 0;
							border-width: 15px;
							border-style: solid;
							border-color: #f5f5f5 transparent transparent;
						}
						dd {
							margin-top: 15px;
							white-space: normal;
							line-height: 1.4;
							font-size: 12px;
							font-size: 1.2rem;
						}
					}
					&.prev {
						float: left;
						dl {
							left: 0;
							&:before {
								margin-left: -30px;
								border-right-color: #f5f5f5;
							}
						}
					}
					&.next {
						float: right;
						dl {
							right: 0;
							&:before {
								border-left-color: #f5f5f5;
							}
						}
					}
					&.index {
						position: absolute;
						width: 100%;
						text-align: center;
						z-index: 10;
					}
				}
			}
		}
		.section {
			.section_header {
				h2 {
					padding-bottom: 20px;
					font-size: 22px;
					font-size: 2.2rem;
					font-weight: bold;
					a {
						color: #111111;
						&:link,
						&:visited {
							color: #111111;
						}
						&:hover,
						&:focus,
						&:active {
							color: #666666;
						}
					}
				}
				nav {
					padding-top: 50px;
					.nav_sort {
						float: left;
						ul {
							margin-left: -15px;
							li {
								display: table-cell;
								padding: 0 15px;
								line-height: 1;
								font-size: 22px;
								font-size: 2.2rem;
								letter-spacing: -0.04em;
								border-right: 1px solid #cccccc;
								&:last-child {
									border-right: 0 none;
								}
								a {
									color: #999999;
									&:link,
									&:visited {
										color: #999999;
									}
									&:hover,
									&:focus,
									&:active {
										color: #111111;
									}
								}
								&.active a {
									color: #111111;
									font-weight: bold;
								}
							}
						}
					}
					.nav_category {
						float: right;
						line-height: 1;
						font-size: 22px;
						font-size: 2.2rem;
						letter-spacing: -0.04em;
					}
				}
			}
			.post_wrap .post {
				.photo,
				.txt {
					display: table-cell;
					vertical-align: top;
				}
				.photo a img {
					background-color: #000000;
				}
			}
		}
	}
}

@media #{$mobile} {
	#container {
		.inner {
			padding: 0 10px;
		}
		#content {
			padding-bottom: 25px;
			.label {
				margin-top: 0;
				margin-left: 10px;
				padding: 0 2px;
				line-height: 12px;
				font-size: 11px;
				font-size: 1.1rem;
				&:before {
					left: -6px;
					border-width: 6px 3px;
				}
			}
			#list_header {
				padding-bottom: 15px;
				.inner {
					h2 {
						font-size: 10px;
						font-size: 1rem;
					}
					.profile .description {
						margin-top: 10px;
						font-size: 10px;
						font-size: 1rem;
					}
					.btn {
						margin-bottom: 10px;
						&.category {
							padding-right: 50px;
							.photo .icon img {
								width: 36px;
								height: 36px;
								margin-top: -18px;
								margin-left: -18px;
							}
						}
						.txt dd {
							margin-top: 5px;
							font-size: 10px;
							font-size: 1rem;
						}
					}
					.profile .txt dd {
						margin-top: 5px;
						font-size: 10px;
						font-size: 1rem;
					}
					.btn .photo,
					.profile .photo {
						width: 50px;
						height: 50px;
					}
					.btn .txt,
					.profile .txt {
						padding-left: 10px;
					}
					.btn .txt dt,
					.profile .txt dt {
						font-size: 13px;
						font-size: 1.3rem;
					}
					.btn .txt dt span,
					.profile .txt dt span {
						font-size: 10px;
						font-size: 1rem;
					}
				}
				&.type_2 .inner {
					.btn .photo,
					.profile .photo {
						width: 30px;
						height: 30px;
					}
					.btn .txt,
					.profile .txt {
						padding-left: 5px;
						dt {
							font-size: 10px;
							font-size: 1rem;
						}
					}
					.btn.category {
						padding-right :26px;
						&:after {
							right: 10px;
							line-height: 30px;
							font-size: 10px;
							font-size: 1rem;
						}
						&:last-child:after {
							right: 6px;
						}
						.photo .icon img {
							width: 20px;
							height: 20px;
							margin-top: -10px;
							margin-left: -10px;
						}
					}
				}
			}
			#nav_next_prev {
				padding: 12px 0;
				.inner ul li {
					font-size: 15px;
					font-size: 1.5rem;
					dl {
						display: none;
					}
				}
			}
			.section .section_header {
				h2 {
					padding-bottom: 10px;
					font-size: 15px;
					font-size: 1.5rem;
				}
				nav {
					padding-top: 25px;
					.nav_sort ul {
						margin-left: -8px;
						li {
							padding: 0 8px;
							font-size: 15px;
							font-size: 1.5rem;
						}
					}
					.nav_category {
						font-size: 15px;
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}

@media #{$tablet} {
	#container #content #nav_next_prev .inner ul li dl {
		width: 80px;
	}
}

.csstransitions #container #content .section .post_wrap .post .photo a img {
	transition: opacity 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#container #content .section {
	.post_wrap .post {
		.photo .noimage {
			display: block;
			width: 100%;
			padding-bottom: 100%;
			background-color: #e5e5e5;
		}
		.txt {
			.post_header {
				.date {
					line-height: 1;
					color: #999999;
					font-size: 12px;
					font-size: 1.2rem;
				}
				h3 {
					margin-top: 4px;
					line-height: 1.36;
					font-size: 22px;
					font-size: 2.2rem;
					letter-spacing: -0.04em;
				}
				.post_meta {
					margin-top: 5px;
					dt,
					dd {
						float: left;
						margin-right: 5px;
						line-height: 1.231;
						color: #999999;
						font-size: 18px;
						font-size: 1.8rem;
					}
					dt span {
						margin-right: 5px;
						img {
							width: 22px;
							//border-radius: 50%;
						}
					}
					dd {
						&.category span {
							margin-right: 5px;
						}
						&.date,
						&.via {
							margin-left: 10px;
						}
					}
				}
			}
			.post_body {
				margin-top: 20px;
				p {
					line-height: 1.36;
					font-size: 16px;
					font-size: 1.6rem;
				}
			}
		}
		a {
			color: #111111;
			&:link,
			&:visited {
				color: #111111;
			}
			&:hover,
			&:focus,
			&:active {
				color: #999999;
			}
		}
		&.over {
			.photo a img {
				opacity: 0.6;
			}
			.txt h3 a {
				color: #999999 !important;
			}
		}
		&.red {
			a {
				&:hover,
				&:focus,
				&:active {
					color: #ff0000 !important;
				}
			}
			&.over .txt h3 a {
				color: #ff0000 !important;
			}
		}
		&.purple {
			a {
				&:hover,
				&:focus,
				&:active {
					color: #ff00ff !important;
				}
			}
			&.over .txt h3 a {
				color: #ff00ff !important;
			}
		}
		&.blue {
			a {
				&:hover,
				&:focus,
				&:active {
					color: #00aeef !important;
				}
			}
			&.over .txt h3 a {
				color: #00aeef !important;
			}
		}
		&.green {
			a {
				&:hover,
				&:focus,
				&:active {
					color: #00cc66 !important;
				}
			}
			&.over .txt h3 a {
				color: #00cc66 !important;
			}
		}
		&.new .photo {
			position: relative;
			&:after {
				content: "";
				display: block;
				position: absolute;
				top: -4px;
				left: -4px;
				width: 54px;
				height: 54px;
				background: url("../images/common/new.png") no-repeat 0 0;
				background-size: 100%;
				z-index: 20;
			}
		}
	}
	.section_footer {
		.more a {
			font-size: 22px;
			font-size: 2.2rem;
		}
		.nav_list {
			position: relative;
			padding-top: 50px;
			text-align: center;
			.nav {
				+ .nav {
					margin-top: 20px;
				}
				a {
					color: #999999;
					&:link,
					&:visited {
						color: #999999;
					}
					&:hover,
					&:focus,
					&:active {
						color: #111111;
					}
				}
				.active a {
					color: #111111;
					font-weight: bold;
				}
				> {
					dl,
					ul {
						display: inline-block;
						margin: 0 auto;
					}
					dl+ {
						dl,
						ul {
							margin-top: 20px;
						}
					}
					ul+ {
						dl,
						ul {
							margin-top: 20px;
						}
					}
					dl {
						dt,
						dd,
						li {
							display: table-cell;
							vertical-align: middle;
							line-height: 1;
							color: #999999;
							font-size: 22px;
							font-size: 2.2rem;
						}
					}
					ul {
						dt,
						dd,
						li {
							display: table-cell;
							vertical-align: middle;
							line-height: 1;
							color: #999999;
							font-size: 22px;
							font-size: 2.2rem;
						}
					}
					dl {
						dd,
						li {
							padding: 0 15px;
							border-right: 1px solid #cccccc;
						}
					}
					ul {
						dd,
						li {
							padding: 0 15px;
							border-right: 1px solid #cccccc;
						}
					}
					dl {
						dd:last-child,
						li:last-child {
							border-right: 0 none;
						}
					}
					ul {
						dd:last-child,
						li:last-child {
							border-right: 0 none;
						}
					}
				}
			}
		}
	}
	&.error {
		padding: 100px 0;
		text-align: center;
		p {
			font-size: 22px;
			font-size: 2.2rem;
			span {
				font-weight: bold;
			}
		}
	}
	&.dot_line .post_wrap .post {
		position: relative;
		&:before {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background: linear-gradient(to right, #ccc, #ccc 60%, transparent 60%, transparent 100%);
			background-size: 5px 5px;
		}
	}
}

@media #{$mobile} {
	#container #content .section {
		.post_wrap .post {
			.txt {
				.post_header {
					.date {
						font-size: 10px;
						font-size: 1rem;
					}
					h3 {
						margin-top: 2px;
						font-size: 15px;
						font-size: 1.5rem;
					}
					.post_meta {
						margin-top: 2px;
						dt,
						dd {
							margin-right: 3px;
							font-size: 12px;
							font-size: 1.2rem;
						}
						dt span img {
							width: 11px;
						}
						dd {
							&.date,
							&.via {
								margin-left: 5px;
							}
							&.category span {
								margin-right: 3px;
							}
						}
					}
				}
				.post_body {
					margin-top: 10px;
					p {
						font-size: 11px;
						font-size: 1.1rem;
					}
				}
			}
			&.new .photo:after {
				top: -2px;
				left: -2px;
				width: 27px;
				height: 27px;
			}
		}
		.section_footer {
			.more a {
				font-size: 12px;
				font-size: 1.2rem;
			}
			.nav_list {
				padding-top: 25px;
			}
		}
	}
}

@media #{$mobile} {
	#container #content .section .section_footer .nav_list .nav {
		+ .nav {
			margin-top: 10px;
		}
		> {
			dl {
				dt,
				dd,
				li {
					font-size: 13px;
					font-size: 1.3rem;
				}
				dd,
				li {
					padding: 0 8px;
				}
			}
			ul {
				dt,
				dd,
				li {
					font-size: 13px;
					font-size: 1.3rem;
				}
				dd,
				li {
					padding: 0 8px;
				}
			}
		}
	}
}

no-cssgradients #container #content .section .section_footer .nav_list:before,
.no-cssgradients #container #content .section.dot_line .post_wrap .post:before {
	background: none;
	border-top: 1px dashed #cccccc;
}

#container #content .section.dot_line .post_wrap .post:last-child:before {
	background: none !important;
}

.no-cssgradients #container #content .section.dot_line .post_wrap .post:last-child:before {
	border-top: 0 none !important;
}

#container #content {
	.section.list {
		&.type_1 .post_wrap .post {
			padding: 25px 0;
			&:first-child {
				padding-top: 0;
			}
			.photo {
				width: 105px;
			}
			.txt {
				padding-left: 15px;
			}
		}
		&.type_2 .inner {
			max-width: 600px;
			.post_wrap .post {
				padding: 50px 0;
				.photo {
					width: 215px;
				}
				.txt {
					padding-left: 25px;
					.post_body p {
						line-height: 1.6;
						color: #666666;
						font-size: 18px;
						font-size: 1.8rem;
						letter-spacing: -0.04em;
						br {
							display: none;
						}
					}
				}
				&.instagram {
					.photo,
					.txt {
						display: block;
					}
					.photo {
						width: auto;
						margin: 0 -20px;
					}
					.txt {
						margin-top: 20px;
						padding-left: 0;
						.post_body p {
							font-size: 20px;
							font-size: 2rem;
						}
					}
				}
			}
		}
		&.type_3 .post_wrap {
			.photo,
			.txt {
				display: block;
			}
			.post {
				margin-bottom: 25px;
				.rank {
					margin-bottom: 15px;
					padding: 8px 10px;
					color: #999999;
					font-size: 18px;
					font-size: 1.8rem;
					background-color: #f5f5f5;
					span {
						color: #111111;
						font-weight: bold;
					}
				}
				.photo {
					float: left;
					width: 105px;
					margin-right: 15px;
					margin-bottom: 5px;
				}
			}
		}
		&.type_4 .post_wrap {
			.photo,
			.txt {
				display: block;
			}
			.post .post_header .post_meta dt {
				display: inline-block;
				float: none;
				margin: 0 auto;
			}
		}
	}
	#detail {
		padding-top: 50px;
		.inner {
			max-width: 600px;
			.detail_header {
				.date {
					color: #999999;
					font-size: 12px;
					font-size: 1.2rem;
				}
				h2 {
					line-height: 1.5;
					font-size: 30px;
					font-size: 3rem;
					letter-spacing: -0.04em;
					.label {
						margin-top: 12px;
					}
				}
			}
			.detail_body {
				margin-top: 40px;
				line-height: 1.8;
				font-size: 18px;
				font-size: 1.8rem;

				img {
					max-width: 100%;
					height: auto;
				}

				a {
					color: #111111;
					border-bottom: 1px dotted #111111;
					&:link,
					&:visited {
						color: #111111;
						border-bottom: 1px dotted #111111;
					}
					&:hover,
					&:focus,
					&:active {
						color: #999999;
						border-bottom-color: #999999;
					}
				}
				> *,
				.content-text p {
					margin-top: 30px;
				}

				.content-text {
					img {
						max-width:100%;
						height:auto;
					}
				}

				p {
					line-height: 1.8;
					font-size: 18px;
					font-size: 1.8rem;
				}

				.column {
					margin-right: -20px;
					img {
						float: left;
						width: 50%;
						padding-right: 20px;
						box-sizing: border-box;
					}
					&.mixed {
						padding-top: 1px;
						img {
							margin-top: 8px;
							margin-bottom: 5px;
						}
						p {
							padding-right: 20px;
						}
					}
				}
				.video {
					position: relative;
					padding-bottom: 56.7%;
					iframe {
						position: absolute;
						width: 100%;
						height: 100%;
					}
				}
				.keywords,
				.share {
					margin-top: 40px;
				}
				.keywords dl,
				.share dl {
					display: inline-block;
					margin: 0 auto;
				}
				.keywords dl a {
					color: #999999;
					border-bottom: 0 none;
					&:link,
					&:visited {
						color: #999999;
						border-bottom: 0 none;
					}
				}
				.share dl a {
					color: #999999;
					border-bottom: 0 none;
					&:link,
					&:visited {
						color: #999999;
						border-bottom: 0 none;
					}
				}
				.keywords dl a {
					&:hover,
					&:focus,
					&:active {
						color: #111111;
					}
				}
				.share dl a {
					&:hover,
					&:focus,
					&:active {
						color: #111111;
					}
				}
				.keywords dl {
					dt,
					dd {
						display: table-cell;
						vertical-align: middle;
						line-height: 1;
						color: #999999;
						font-size: 18px;
						font-size: 1.8rem;
					}
				}
				.share dl {
					dt,
					dd {
						display: table-cell;
						vertical-align: middle;
						line-height: 1;
						color: #999999;
						font-size: 18px;
						font-size: 1.8rem;
					}
				}
				.keywords dl dd {
					padding: 0 10px;
					border-right: 1px solid #cccccc;
					&:last-child {
						border-right: 0 none;
					}
				}
				.share dl {
					dt {
						padding-right: 15px;
					}
					dd {
						padding: 0 15px;
						a {
							display: block;
							width: 26px;
							&:before {
								font-family: roc;
								font-size: 26px;
								font-size: 2.6rem;
							}
						}
						&.facebook a:before {
							content: "f";
						}
						&.twitter a:before {
							content: "t";
						}
						&.google_plus a:before {
							content: "g";
						}
					}
				}
			}
			.detail_footer {
				position: relative;
				margin-top: 50px;
				padding-top: 50px;
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background: linear-gradient(to right, #ccc, #ccc 60%, transparent 60%, transparent 100%);
					background-size: 5px 5px;
				}
			}
		}
	}
}

@media #{$mobile} {
	#container #content {
		.section.list {
			&.type_1 .post_wrap .post {
				padding: 12px 0;
				.photo {
					width: 50px;
				}
				.txt {
					padding-left: 8px;
				}
			}
			&.type_2 .inner {
				max-width: none;
				.post_wrap {
					width: 100%;
					box-sizing: border-box;
					.post {
						padding: 25px 0;
						.photo {
							width: 105px;
						}
						.txt {
							padding-left: 12px;
							.post_body p {
								font-size: 13px;
								font-size: 1.3rem;
							}
						}
						&.instagram {
							.photo {
								margin: 0 -10px;
							}
							.txt {
								margin-top: 10px;
								.post_body p {
									font-size: 14px;
									font-size: 1.4rem;
								}
							}
						}
					}
				}
			}
			&.type_3 .post_wrap .post .photo {
				width: 50px;
			}
		}
		#detail {
			padding-top: 25px;
			.inner {
				max-width: none;
				.detail_header {
					.date {
						font-size: 10px;
						font-size: 1rem;
					}
					h2 {
						margin-top: 4px;
						font-size: 18px;
						font-size: 1.8rem;
					}
				}
				.detail_body {
					font-size: 13px;
					font-size: 1.3rem;
					margin-top: 20px;

					> * {
						margin-top: 15px;
					}

					img {
						max-width: 100%;
						height: auto;
					}

					.content-text {
					}
					p {
						font-size: 13px;
						font-size: 1.3rem;
					}
					.column {
						margin-right: -10px;
						img {
							padding-right: 10px;
						}
						&.mixed {
							margin-right: 0;
							padding-top: 0;
							img {
								float: none;
								width: 100%;
								margin-bottom: 0;
								padding-right: 0;
							}
							p {
								margin-top: 15px;
								padding-right: 0;
							}
						}
					}
					.keywords,
					.share {
						margin-top: 20px;
					}
					.keywords dl {
						dt {
							font-size: 10px;
							font-size: 1rem;
						}
						dd {
							font-size: 10px;
							font-size: 1rem;
							padding: 0 5px;
						}
					}
					.share dl {
						dt,
						dd {
							font-size: 10px;
							font-size: 1rem;
						}
						dt {
							padding-right: 8px;
						}
						dd {
							padding: 0 5px;
							a {
								width: 13px;
								&:before {
									font-size: 13px;
									font-size: 1.3rem;
								}
							}
						}
					}
				}
				.detail_footer {
					margin-top: 25px;
					padding-top: 25px;
				}
			}
		}
	}
}

.no-cssgradients #container #content #detail .inner .detail_footer:before {
	background: none;
	border-top: 1px dashed #cccccc;
}
