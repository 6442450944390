.module_posts_wrapper {
  max-width: 1222px;
  margin: 0 auto;
  padding: 0 30px;

  @media #{$mobile} {
    margin: 0 20px;
    padding: 0;
  }

}

.module_posts {
  display: flex;
  align-items: strech;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-left: -26px;

  @media #{$mobile} {
    margin-left: -20px;
  }

  a {
    transition: all 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
    &:link,
    &:visited {
      color: #000;
    }
    &:hover {
      opacity: 0.6;
    }
  }
}


.module_posts__item {
  width: 16.666666667%;
  padding-left:26px;
  padding-bottom: 1.5em;
  box-sizing: border-box;

  @media #{$tablet_large} {
    width: 25%;
  }

  @media #{$mobile} {
    width: 50%;
    padding-left: 20px;
  }

}

.module_posts__item__photo {
  a {
    display: block;
  }
}

.module_posts__item__photo__cover {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  overflow: hidden;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    display: none;
  }
}

.module_posts__item__photo__noimage {
}

.module_posts__item__content {
  margin-top: 1em;
}

.module_posts__item__top {
  display: table;
  width: 100%;

  & > * {
    display: table-cell;
    vertical-align: top;
  }
}

.module_posts__item__date {
  font-size: 1rem;
  color: #999;
}

.module_posts__item__labels {
  color: #990;
  text-align: right;
  font-size: 1rem;
}

.module_posts__item__title {
  font-size: 1.4rem;
  margin: .2em 0 .4em;
}

.module_posts__item__label {
}

.module_posts__item__meta {
  dd {
    margin-top: .5em;
    font-style: italic;
    font-size: 1.1rem;
    color: #fff;

    span {
      color: #868686;
      margin-right: .5em;
    }

  }
}


.module_posts__item__meta__author__a {
  display: table;

  & > * {
    display: table-cell;
    vertical-align: middle;
  }

  img {
    width: 20px;
    height: 20px;
    //border-radius: 20px;
  }

  span {
    padding-right: 10px;
  }

  em {
    font-size: 1.1rem;
    font-style: italic;
  }


}


// Home 用 modifier
// ==============================
.module_posts--home {
  a {
    &:link,
    &:visited {
      color: #fff;
    }
  }

  .module_posts__item__labels {
    color: #ff0;
  }

}
