@charset "utf-8";

.effect-grayscale img {
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}


.effect-grayscale--hover img {
  -webkit-transition: .15s ease-in-out;
  transition: .15s ease-in-out;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}

.effect-grayscale--hover:hover img {
  -webkit-filter: grayscale(0%);
  filter: none;
}


.effect-radius {
  // -webkit-border-radius: 50%;
  //         border-radius: 50%;
}