/*
*   Owl Carousel Owl Demo Theme
* v1.3.3
*/

.owl-theme .owl-controls{
  text-align: center;
}

/* Styling Next and Prev buttons */

.owl-theme .owl-controls .owl-buttons {
  div {
    display: block;
    position: absolute;
    top: 50%;
    margin: -12px 0 0;
    padding: 0;

    width: 24px;
    height: 24px;
    overflow: hidden;

    font-size: 10px;
    line-height: 0;

    text-decoration: none;
    text-indent: -9999px;
    cursor: pointer;

    @media #{$mobile} {
      width: 12px;
      height: 12px;
      margin-top: -6px;
    }

    &:hover {
      opacity: 0.5;
    }

  }

  .owl-prev {
    left: -35px;
    background:url(../images/common/icon-arrow-left.png) center center no-repeat;
    background-size: cover;

    @media #{$mobile} {
      left: 6px;
    }
  }

  .owl-next {
    right: -35px;
    background:url(../images/common/icon-arrow-right.png) center center no-repeat;
    background-size: cover;

    @media #{$mobile} {
      right: 6px;
    }
  }


}

/* Clickable class fix problem with hover on touch devices */
/* Use it for non-touch hover action */
.owl-theme .owl-controls.clickable .owl-buttons div:hover{
  filter: Alpha(Opacity=100);/*IE7 fix*/
  opacity: 1;
  text-decoration: none;
}

/* Styling Pagination*/
.owl-theme .owl-controls .owl-pagination {
  display: none;
}

.owl-theme .owl-controls .owl-page{
  display: inline-block;
  zoom: 1;
  *display: inline;/*IE7 life-saver */
}
.owl-theme .owl-controls .owl-page span{
  display: block;
  width: 12px;
  height: 12px;
  margin: 5px 7px;
  filter: Alpha(Opacity=50);/*IE7 fix*/
  opacity: 0.5;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  background: #869791;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span{
  filter: Alpha(Opacity=100);/*IE7 fix*/
  opacity: 1;
}

/* If PaginationNumbers is true */

.owl-theme .owl-controls .owl-page span.owl-numbers{
  height: auto;
  width: auto;
  color: #FFF;
  padding: 2px 10px;
  font-size: 12px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

/* preloading images */
.owl-item.loading{
  min-height: 150px;
  // background: url(AjaxLoader.gif) no-repeat center center
}