@charset "utf-8";

/* about.css */
#aobut #container {
	display: block !important;
	.loader_wrap {
		display: none !important;
	}
}

#about #container #content {
	background-color: #111111;
	.inner {
		width: 640px;
		color: #ffffff;
		header p {
			line-height: 1.52;
			font-size: 46px;
			font-size: 4.6rem;
			font-weight: 300;
		}
		> p {
			margin-top: 30px;
			line-height: 1.9;
			font-size: 22px;
			font-size: 2.2rem;
		}
		footer {
			margin-top: 30px;
			dl {
				dt {
					line-height: 1.9;
					font-size: 22px;
					font-size: 2.2rem;
				}
				dd {
					width: 285px;
					margin-top: 30px;
				}
			}
			.date {
				margin-top: 45px;
				color: #999999;
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
	}
}

@media #{$mobile} {
	#about #container #content .inner {
		width: auto;
		header p {
			font-size: 23px;
			font-size: 2.3rem;
		}
		> p {
			margin-top: 15px;
			font-size: 15px;
			font-size: 1.5rem;
		}
		footer {
			margin-top: 15px;
			dl {
				dt {
					font-size: 15px;
					font-size: 1.5rem;
				}
				dd {
					width: 140px;
					margin-top: 15px;
				}
			}
			.date {
				margin-top: 20px;
				font-size: 10px;
				font-size: 1rem;
			}
		}
	}
}
