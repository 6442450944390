@charset "utf-8";

/* module.css */

.icon {
	// border-radius: 50%;
	overflow: hidden;
}

#container #content {
	#detail .inner .detail_footer .profile {
		a {
			display: inline-block;
			.photo,
			.txt {
				display: table-cell;
				vertical-align: middle;
			}
			.photo {
				width: 105px;
				height: 105px;
				padding-bottom: 0;
			}
			.txt {
				padding-left: 20px;
				font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", sans-serif;
				dt {
					font-size: 22px;
					font-size: 2.2rem;
					font-weight: bold;
					letter-spacing: -0.02em;
				}
				dd {
					margin-top: 10px;
					font-size: 16px;
					font-size: 1.6rem;
				}
			}
			color: #111111;
			&:link,
			&:visited {
				color: #111111;
			}
			&:hover,
			&:focus,
			&:active {
				color: #999999;
			}
		}
		.description {
			margin-top: 15px;
			line-height: 1.6;
			font-size: 16px;
			font-size: 1.6rem;
			dl {
				dt {
					display: table-cell;
				}
				dd {
					display: table-cell;
					a {
						color: #111111;
						border-bottom: 1px dotted #111111;
						&:link,
						&:visited {
							color: #111111;
							border-bottom: 1px dotted #111111;
						}
						&:hover,
						&:focus,
						&:active {
							color: #999999;
							border-bottom-color: #999999;
						}
					}
				}
			}
		}
	}
	#related {
		margin: 50px 0 -50px;
		padding: 50px 0 25px;
		background-color: #f5f5f5;
		.inner {
			max-width: 600px;
		}
	}
	.panel {
		width: 640px;
		margin: 0 auto;
		.section_header {
			padding-bottom: 45px;
			h2 {
				text-align: center;
				color: #999999;
				font-size: 18px;
				font-size: 1.8rem;
			}
		}
		.freshness {
			padding-top: 0;
			dl {
				position: relative;
				padding: 25px 10px 0;
				&:before {
					content: "";
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background: linear-gradient(to right, #666, #666 60%, transparent 60%, transparent 100%);
					background-size: 5px 5px;
					z-index: 10;
				}
			}
		}
	}
}

@media #{$mobile} {
	#container #content {
		#detail .inner .detail_footer .profile {
			a {
				.photo {
					width: 50px;
					height: 50px;
				}
				.txt {
					padding-left: 10px;
					dt {
						font-size: 15px;
						font-size: 1.5rem;
					}
					dd {
						margin-top: 5px;
						font-size: 12px;
						font-size: 1.2rem;
					}
				}
			}
			.description {
				margin-top: 10px;
				font-size: 10px;
				font-size: 1rem;
			}
		}
		#related {
			margin: 25px 0 -25px;
			padding: 25px 0 12px;
			.inner {
				max-width: none;
			}
		}
		.panel {
			width: 100%;
			.section_header {
				padding-bottom: 22px;
				h2 {
					font-size: 9px;
					font-size: 0.9rem;
				}
			}
		}
	}
}

.no-cssgradients #container #content .panel .freshness dl:before {
	background: none;
	border-top: 1px dashed #cccccc;
}

#container .module_search {
	height: 80px;
	padding: 20px 0;
	box-sizing: border-box;
	form {
		label.icon.search {
			/*display: block;*/
			float: left;
			margin-right: 10px;
			&:before {
				content: "🔎";
				font-family: roc;
				font-size: 32px;
				font-size: 3.2rem;
			}
		}
		input {
			line-height: 40px;
			font-size: 22px;
			font-size: 2.2rem;
			background-color: transparent;
			border: 0 none;
			outline: none;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			&[type=text] {
				float: left;
			}
			&[type=submit] {
				float: right;
				color: #999999;
				cursor: pointer;
			}
		}
	}
}

@media #{$mobile} {
	#container .module_search {
		height: 40px;
		padding: 10px 0;
		form {
			label.icon.search:before {
				font-size: 16px;
				font-size: 1.6rem;
			}
			input {
				line-height: 20px;
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
	}
}

.csstransitions #container .module_search form input[type=submit] {
	transition: color 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#container {
	.module_search form input[type=submit]:hover {
		color: #000000;
	}
	.freshness {
		padding: 25px 0;
		text-align: center;
		dl {
			display: inline-block;
			margin: 0 auto;
			dt {
				display: table-cell;
				vertical-align: middle;
				color: #999999;
				font-size: 18px;
				font-size: 1.8rem;
			}
			dd {
				display: table-cell;
				vertical-align: middle;
				color: #999999;
				font-size: 18px;
				font-size: 1.8rem;
				padding-left: 12px;
				span {
					display: block;
					position: relative;
					padding-left: 30px;
					line-height: 1;
					&:before {
						content: "";
						display: inline-block;
						position: absolute;
						top: 50%;
						left: 0;
						width: 20px;
						height: 20px;
						margin-top: -12px;
						border-width: 2px;
						border-style: solid;
						border-radius: 50%;
					}
				}
				&.red span:before {
					border-color: #ff0000;
				}
				&.purple span:before {
					border-color: #ff00ff;
				}
				&.blue span:before {
					border-color: #00aeef;
				}
				&.green span:before {
					border-color: #00cc66;
				}
			}
		}
	}
	.module_freshness {
		.red .name {
			color: #ff0000 !important;
		}
		.purple .name {
			color: #ff00ff !important;
		}
		.blue .name {
			color: #00aeef !important;
		}
		.green .name {
			color: #00cc66 !important;
		}
	}
	.module_categories .category {
		float: left;
		width: 25%;
		.wrap {
			position: relative;
			overflow: hidden;
			.name {
				position: absolute;
				bottom: 25px;
				left: 0;
				width: 100%;
				text-align: center;
				line-height: 1;
				color: #ffffff;
				font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", sans-serif;
				font-size: 22px;
				font-size: 2.2rem;
				font-weight: bold;
				z-index: 30;
				text-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
			}
			.img {
				position: relative;
				z-index: 10;
				img {
					width: 100%;
					height: auto;
				}
				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background: linear-gradient(transparent 50%, rgba(17, 17, 17, 0.5) 75%, rgba(17, 17, 17, 0.6));
					*zoom: 1;
					z-index: 20;
				}
			}
			.icon {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #111111;
				z-index: 20;
			}
		}
		&.days:hover .wrap .icon {
			display: none !important;
			opacity: 0 !important;
		}
		.wrap .icon span {
			content: "";
			display: block;
			position: absolute;
			top: 40%;
			left: 50%;
			width: 46%;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: 100%;
		}
		&.cars .icon span {
			background-image: url("../images/categories/cars/icon.png");
		}
		&.watches .icon span {
			background-image: url("../images/categories/watches/icon.png");
		}
		&.art .icon span {
			background-image: url("../images/categories/art/icon.png");
		}
		&.eats .icon span {
			background-image: url("../images/categories/eats/icon.png");
		}
		&.trips .icon span {
			background-image: url("../images/categories/trips/icon.png");
		}
		&.music .icon span {
			background-image: url("../images/categories/music/icon.png");
		}
		&.movies .icon span {
			background-image: url("../images/categories/movies/icon.png");
		}
		&.books .icon span {
			background-image: url("../images/categories/books/icon.png");
		}
		&.sneakers .icon span {
			background-image: url("../images/categories/sneakers/icon.png");
		}
		&.life .icon span {
			background-image: url("../images/categories/life/icon.png");
		}
		&.clothing .icon span {
			background-image: url("../images/categories/clothing/icon.png");
		}
		&.xtream .icon span {
			background-image: url("../images/categories/xtream/icon.png");
		}
		&.digital .icon span {
			background-image: url("../images/categories/digital/icon.png");
		}
		&.analog .icon span {
			background-image: url("../images/categories/analog/icon.png");
		}
		&.instagram .icon span {
			background-image: url("../images/categories/instagram/icon.png");
		}
		&.nike .icon span {
			background-image: url("../images/categories/nike/icon.png");
		}
	}
	.module_members {
		margin-right: -20px;
		.member {
			float: left;
			width: 25%;
			margin-bottom: 20px;
			padding-right: 20px;
			text-align: center;
			box-sizing: border-box;
			.name {
				margin-top: 10px;
				line-height: 1.2;
				color: #cccccc;
				font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", sans-serif;
				font-size: 18px;
			}
			.date {
				margin-top: 5px;
				font-size: 13px;
				font-size: 1.3rem;
			}
		}
	}
	.switch_monochrome .photo {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		li {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			//border-radius: 50%;
			overflow: hidden;
			&.color {
				z-index: 20;
			}
			&.monochrome {
				z-index: 10;
			}
		}
	}
	.loader_wrap {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000000;
		z-index: 888888;
	}
	.loader {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		margin-top: -25px;
		margin-left: -25px;
		background: url("../images/common/loader.png");
		overflow: hidden;
	}
	.slide {
		visibility: hidden;
		position: relative;
		z-index: 10;
	}
}


// https://clip.backlog.jp/view/RINGOFCOLOUR-320
// 画像の高さ端数でてカラムレイアウト崩れる場合がある問題対応
@media #{$mobile} {
	.module_freshness > .module_freshness {
		display : -webkit-flex;
		display: flex;
		flex-wrap: wrap;
    -webkit-flex-wrap:wrap;
	}
}


@media #{$mobile} {
	#container .freshness {
		padding: 12px 0;
	}
}

@media #{$mobile} {
	#container .freshness dl {
		dt,
		dd {
			font-size: 9px;
			font-size: 0.9rem;
			letter-spacing: -0.04em;
		}
	}
}

@media #{$mobile} {
	#container .freshness dl dd {
		padding-left: 6px;
	}
}

@media #{$mobile} {
	#container .freshness dl dd span {
		padding-left: 15px;
	}
}

@media #{$mobile} {
	#container .freshness dl dd span:before {
		width: 10px;
		height: 10px;
		margin-top: -6px;
		border-width: 1px;
	}
}

@media #{$tablet} {
	#container .module_categories .category .wrap .name {
		font-size: 20px;
		font-size: 2rem;
	}
}

@media #{$mobile} {
	#container .module_categories .category .wrap .name {
		bottom: 12px;
		font-size: 15px;
		font-size: 1.5rem;
	}
}

@media #{$mobile} {
	#container .module_members .member .date {
		font-size: 10px;
		font-size: 1rem;
	}
}

@media #{$mobile} {
	#container .module_members {
		margin-right: -10px;
		.member {
			margin-bottom: 10px;
			padding-right: 10px;
			.name {
				font-size: 12px;
			}
		}
	}
}

@media #{$mobile} {
	#container {
		.freshness {
			padding: 12px 0;
			dl {
				dt,
				dd {
					font-size: 9px;
					font-size: 0.9rem;
					letter-spacing: -0.04em;
				}
				dd {
					padding-left: 6px;
					span {
						padding-left: 15px;
						&:before {
							width: 10px;
							height: 10px;
							margin-top: -6px;
							border-width: 1px;
						}
					}
				}
			}
		}
		.module_categories .category .wrap .name {
			bottom: 12px;
			font-size: 15px;
			font-size: 1.5rem;
		}
		margin-right: -10px;
		.module_members .member {
			margin-bottom: 10px;
			padding-right: 10px;
			.name {
				font-size: 12px;
			}
			.date {
				font-size: 10px;
				font-size: 1rem;
			}
		}
		.loader {
			width: 25px;
			height: 25px;
			margin-top: -12px;
			margin-left: -12px;
			background-size: auto 25px;
		}
	}
}

@media #{$tablet} {
	#container .module_categories .category .wrap .name {
		font-size: 20px;
		font-size: 2rem;
	}
}

.no-js #container .slide {
	visibility: visible;
}

#container .slide {
	.slide_wrap {
		position: relative;
		z-index: 10;
		ul,
		.slide_list {
			position: relative;
		}
		ul> *,
		.slide_list> * {
			width: 100%;
			text-align: center;
			vertical-align: middle;
		}
	}
	.slide_nav {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		margin-top: -30px;
		z-index: 20;
		.btn {
			position: absolute;
			top: 0;
			margin-top: -25px;
			a {
				width: 50px;
				height: 50px;
				text-align: center;
				&:after {
					position: absolute;
					line-height: 50px;
					color: #ffffff;
					font-size: 48px;
					font-size: 4.8rem;
				}
			}
			&.next {
				right: 20px;
				a:after {
					right: -1px;
				}
			}
			&.prev {
				left: 20px;
				a:after {
					left: -1px;
				}
			}
			&.over span {
				color: #cdbc9e;
			}
		}
	}
	.dot {
		width: 100%;
		margin-top: 15px;
		text-align: center;
		z-index: 20;
		ul {
			display: table;
			margin: 0 auto;
			li {
				display: table-cell;
				padding: 0 5px;
				&:after {
					content: "";
					display: block;
					width: 10px;
					height: 10px;
					border: 1px solid #ffffff;
					border-radius: 50%;
					box-sizing: border-box;
				}
				&.active:after,
				&.over:after {
					background-color: #ffffff;
				}
			}
		}
	}
}

@media #{$mobile} {
	#container .slide .dot {
		margin-top: 10px;
		ul li {
			padding: 0 2px;
			&:after {
				width: 5px;
				height: 5px;
			}
		}
	}
}