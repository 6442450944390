@charset "utf-8";

/* footer.css */

#container {
	#footer {
		text-align: center;
		.module_search {
			background-color: #e5e5e5;
			border-top: 1px solid #cccccc;
			form input[type=text] {
				width: 80%;
			}
		}
		.logo_wrap {
			margin-top: 50px;
			h1 {
				width: 240px;
				margin: 0 auto;
			}
			.sns {
				display: none;
				float: right;
				li {
					display: table-cell;
					padding-left: 30px;
				}
			}
		}
		.module_members .inner .member_wrap {
			display: inline-block;
			margin: 0 auto;
		}
		nav {
			display: inline-block;
			margin: 40px auto;
			p, ul {
				display: table-cell;
				line-height: 1;
				color: #999999;
				font-size: 18px;
				font-size: 1.8rem;
			}
			p {
				padding: 0 10px;
			}
			ul li {
				display: table-cell;
				padding: 0 10px;
				border-left: 1px solid #666666;
				a {
					color: #999999;
					&:link, &:visited {
						color: #999999;
					}
					&:hover, &:focus, &:active {
						color: #ffffff;
					}
				}
			}
		}
	}
	#banner {
		padding: 20px 0 30px;
		background-color: #000000;
		.inner {
			width: 600px;
			padding: 0;
			overflow: hidden;
		}
	}
}


@media #{$mobile} {
	#container {
		#footer {
			.logo_wrap {
				margin-top: 25px;
				h1 {
					width: 101px;
				}
				.sns li {
					padding-left: 15px;
				}
			}
			.module_members .inner .member_wrap .member .name {
				font-size: 10px;
				font-size: 1rem;
			}
			nav {
				margin: 20px auto;
				p, ul li {
					padding: 0 5px;
				}
				p, ul {
					font-size: 9px;
					font-size: 0.9rem;
				}
			}
		}
		#banner {
			padding: 10px 10px 15px;
			.inner {
				width: 100%;
			}
		}
	}
}
