@charset "UTF-8";

#container {
	#header .inner, #overlay #overlay_container .btn_wrap .inner {
		*zoom: 1;
	}
	#footer {
		.logo_wrap, .module_members .inner .member_wrap {
			*zoom: 1;
		}
	}
	#content {
		#list_header .inner, #nav_next_prev .inner ul {
			*zoom: 1;
		}
		.section {
			.section_header nav {
				*zoom: 1;
			}
			.post_wrap .post .txt .post_header .post_meta {
				*zoom: 1;
				dt {
					*zoom: 1;
				}
			}
			&.list.type_3 .post_wrap .post {
				*zoom: 1;
			}
		}
		#detail .inner .detail_body .column {
			*zoom: 1;
		}
	}
	.module_search form, .module_categories, .module_members {
		*zoom: 1;
	}
}

#top #container {
	#footer .module_members .inner .member_wrap, #content .posts {
		*zoom: 1;
	}
}

#contact #container #content section .section_body .inner form dl {
	*zoom: 1;
}

#container {
	#header .inner:after, #overlay #overlay_container .btn_wrap .inner:after {
		content: "";
		display: table;
		clear: both;
	}
	#footer {
		.logo_wrap:after, .module_members .inner .member_wrap:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	#content {
		#list_header .inner:after, #nav_next_prev .inner ul:after {
			content: "";
			display: table;
			clear: both;
		}
		.section {
			.section_header nav:after {
				content: "";
				display: table;
				clear: both;
			}
			.post_wrap .post .txt .post_header .post_meta {
				&:after, dt:after {
					content: "";
					display: table;
					clear: both;
				}
			}
			&.list.type_3 .post_wrap .post:after {
				content: "";
				display: table;
				clear: both;
			}
		}
		#detail .inner .detail_body .column:after {
			content: "";
			display: table;
			clear: both;
		}
	}
	.module_search form:after, .module_categories:after, .module_members:after {
		content: "";
		display: table;
		clear: both;
	}
}

#top #container {
	#footer .module_members .inner .member_wrap:after, #content .posts:after {
		content: "";
		display: table;
		clear: both;
	}
}

#contact #container #content section .section_body .inner form dl:after {
	content: "";
	display: table;
	clear: both;
}

@font-face {
	font-family: "roc";
	src: url("../fonts/roc.eot");
	src: url("../fonts/roc.eot?#iefix") format("embedded-opentype"), url("../fonts/roc.ttf") format("truetype"), url("../fonts/roc.woff") format("woff"), url("../fonts/roc.svg") format("svg");
	font-weight: normal;
	font-style: normal;
}


html {
	color: #000;
	background: #FFF;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
	margin: 0;
	padding: 0;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

fieldset, img {
	border: 0;
}

address, caption, cite, code, dfn, em, strong, th, var {
	font-style: normal;
	font-weight: normal;
}

ol, ul {
	list-style: none;
}

caption, th {
	text-align: left;
}

h1, h2, h3, h4, h5, h6 {
	font-size: 100%;
	font-weight: normal;
}

q {
	&:before, &:after {
		content: "";
	}
}

abbr, acronym {
	border: 0;
	font-variant: normal;
}

sup {
	vertical-align: text-top;
}

sub {
	vertical-align: text-bottom;
}

input, textarea, select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
}

input, textarea, select {
	*font-size: 100%;
}

legend {
	color: #000;
}

iframe {
	border: 0 none;
}

img {
	vertical-align: bottom;
}

.fltLeft {
	padding: 0;
	margin: 0;
	float: left;
}

.fltRight {
	padding: 0;
	margin: 0;
	float: right;
}

.mt0 {
	margin-top: 0 !important;
}

.mr0 {
	margin-right: 0 !important;
}

.mb0 {
	margin-bottom: 0 !important;
}

.ml0 {
	margin-left: 0 !important;
}

.pt0 {
	padding-top: 0 !important;
}

.pr0 {
	padding-right: 0 !important;
}

.pb0 {
	padding-bottom: 0 !important;
}

.pl0 {
	padding-left: 0 !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mr5 {
	margin-right: 5px !important;
}

.mb5 {
	margin-bottom: 5px !important;
}

.ml5 {
	margin-left: 5px !important;
}

.pt5 {
	padding-top: 5px !important;
}

.pr5 {
	padding-right: 5px !important;
}

.pb5 {
	padding-bottom: 5px !important;
}

.pl5 {
	padding-left: 5px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mr10 {
	margin-right: 10px !important;
}

.mb10 {
	margin-bottom: 10px !important;
}

.ml10 {
	margin-left: 10px !important;
}

.pt10 {
	padding-top: 10px !important;
}

.pr10 {
	padding-right: 10px !important;
}

.pb10 {
	padding-bottom: 10px !important;
}

.pl10 {
	padding-left: 10px !important;
}

.mt15 {
	margin-top: 15px !important;
}

.mr15 {
	margin-right: 15px !important;
}

.mb15 {
	margin-bottom: 15px !important;
}

.ml15 {
	margin-left: 15px !important;
}

.pt15 {
	padding-top: 15px !important;
}

.pr15 {
	padding-right: 15px !important;
}

.pb15 {
	padding-bottom: 15px !important;
}

.pl15 {
	padding-left: 15px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mr20 {
	margin-right: 20px !important;
}

.mb20 {
	margin-bottom: 20px !important;
}

.ml20 {
	margin-left: 20px !important;
}

.pt20 {
	padding-top: 20px !important;
}

.pr20 {
	padding-right: 20px !important;
}

.pb20 {
	padding-bottom: 20px !important;
}

.pl20 {
	padding-left: 20px !important;
}

.mt25 {
	margin-top: 25px !important;
}

.mr25 {
	margin-right: 25px !important;
}

.mb25 {
	margin-bottom: 25px !important;
}

.ml25 {
	margin-left: 25px !important;
}

.pt25 {
	padding-top: 25px !important;
}

.pr25 {
	padding-right: 25px !important;
}

.pb25 {
	padding-bottom: 25px !important;
}

.pl25 {
	padding-left: 25px !important;
}

.mt30 {
	margin-top: 30px !important;
}

.mr30 {
	margin-right: 30px !important;
}

.mb30 {
	margin-bottom: 30px !important;
}

.ml30 {
	margin-left: 30px !important;
}

.pt30 {
	padding-top: 30px !important;
}

.pr30 {
	padding-right: 30px !important;
}

.pb30 {
	padding-bottom: 30px !important;
}

.pl30 {
	padding-left: 30px !important;
}

.mt35 {
	margin-top: 35px !important;
}

.mr35 {
	margin-right: 35px !important;
}

.mb35 {
	margin-bottom: 35px !important;
}

.ml35 {
	margin-left: 35px !important;
}

.pt35 {
	padding-top: 35px !important;
}

.pr35 {
	padding-right: 35px !important;
}

.pb35 {
	padding-bottom: 35px !important;
}

.pl35 {
	padding-left: 35px !important;
}

.mt40 {
	margin-top: 40px !important;
}

.mr40 {
	margin-right: 40px !important;
}

.mb40 {
	margin-bottom: 40px !important;
}

.ml40 {
	margin-left: 40px !important;
}

.pt40 {
	padding-top: 40px !important;
}

.pr40 {
	padding-right: 40px !important;
}

.pb40 {
	padding-bottom: 40px !important;
}

.pl40 {
	padding-left: 40px !important;
}

.mt45 {
	margin-top: 45px !important;
}

.mr45 {
	margin-right: 45px !important;
}

.mb45 {
	margin-bottom: 45px !important;
}

.ml45 {
	margin-left: 45px !important;
}

.pt45 {
	padding-top: 45px !important;
}

.pr45 {
	padding-right: 45px !important;
}

.pb45 {
	padding-bottom: 45px !important;
}

.pl45 {
	padding-left: 45px !important;
}

.mt50 {
	margin-top: 50px !important;
}

.mr50 {
	margin-right: 50px !important;
}

.mb50 {
	margin-bottom: 50px !important;
}

.ml50 {
	margin-left: 50px !important;
}

.pt50 {
	padding-top: 50px !important;
}

.pr50 {
	padding-right: 50px !important;
}

.pb50 {
	padding-bottom: 50px !important;
}

.pl50 {
	padding-left: 50px !important;
}

.clearfix {
	*zoom: 1;
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

html, body {
	width: 100%;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	font: 62.5%/1.231 "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", serif;
	line-height: 1.231;
	font-size: 62.5%;
	background-color: #000000;
	overflow-x: auto;
	overflow-y: scroll;
}

body {
	text-align: center;
	font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", "ryo-gothic-plusn", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	-ms-font-smoothing: antialiased;
	-o-font-smoothing: antialiased;
	font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
	-ms-text-size-adjust: none;
	text-size-adjust: none;
}

.touchevents body {
	-webkit-overflow-scrolling: touch;
}

a {
	text-decoration: none;
	color: #999999;
	&:link, &:visited {
		text-decoration: none;
		color: #999999;
	}
	&:hover, &:focus, &:active {
		color: #333333;
	}
}

.csstransitions.no-touchevents a {
	transition-property: color, background-color, border, opacity;
	transition-duration: 240ms;
	transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.search button {
	span {
		color: #999999;
		transition: color 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
	}
	&:hover span {
		color: #333333 !important;
	}
}

#container {
	display: none;
	position: relative;
	width: 100%;
	height: 100%;
	text-align: left;
	color: #111111;
	background-color: #111111;
	box-sizing: border-box;
	z-index: 10;
}

.no-js #container {
	display: block;
}

