@charset "utf-8";

/* top.css */

#top #container {

  #content {
    padding-bottom: 0px;
  }

  #footer {
    overflow: hidden;
    .module_members {
      margin-top: 50px;
      margin-right: 0;
      .inner {
        margin: 0 auto;
        .member_wrap {
          margin-right: -20px;
          .member {
            width: 25%;
          }
        }
      }
    }
  }
  #main_area {
    padding-bottom: 70px;
    background-color: #111111;
    .module_categories {
      max-width: 1440px;
      margin: 0 auto;
      .category {
        width: 12.5%;
        .wrap {
          .img ul {
            position: relative;
            width: 100%;
            z-index: 10;
            li {
              position: absolute;
              width: 100%;
            }
          }
          .icon {
            -webkit-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            -o-backface-visibility: hidden;
            backface-visibility: hidden;
          }
        }
      }
    }
  }
  #featured {
    padding: 35px 0;
    background-color: #1f1f1f;
    border-top: 15px solid #181818;
    .inner {
      padding: 0 10px;
      text-align: center;
      .slide {
        .slide_wrap {
          overflow: hidden;
        }
        .dot {
          position: absolute;
          margin-top: 12px;
        }
      }
      ul {
        margin: 0 auto;
        li a img {
          background-color: #111111;
        }
      }
    }
  }
}


@media #{$tb_mb} {
  #top #container #main_area .module_categories .category {
    width: 25%;
  }
}


@media #{$mobile} {
  #top #container {
    #footer .module_members {
      margin-top: 25px;
      .inner .member_wrap {
        margin-right: -10px;
      }
    }
    #main_area {
      padding-bottom: 40px;
    }
    #featured {
      padding: 15px 0;
      border-top-width: 8px;
      .inner {
        .slide .dot {
          position: static;
          margin-top: 10px;
          ul {
            display: table;
            margin-right: auto;
          }
        }
        ul {
          display: block;
          margin-right: 0;
        }
      }
    }
  }
}


.csstransitions #top #container #featured .inner ul li a img {
  transition: opacity 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#top #container {
  #featured .inner ul {
    li a {
      img, &:link img, &:visited img {
        opacity: 1;
      }
      &:hover img, &:focus img, &:active img {
        opacity: 0.6;
      }
    }
    &.single {
      display: inline-block;
      width: 600px;
      li {
        width: 100%;
      }
    }
    &.multiple li {
      padding: 0 10px;
      box-sizing: border-box;
    }
  }
  #content {
    padding-top: 50px;
    background-color: #2d2d2d;
    // border-top: 15px solid #262626;
    .posts {
      padding-bottom: 50px;
      #main {
        float: left;
        width: 60%;
      }
      #side {
        float: right;
        width: 35%;
      }
    }
    .section {
      .section_header h2 a {
        color: #cccccc;
        &:link, &:visited {
          color: #cccccc;
        }
        &:hover, &:focus, &:active {
          color: #999999;
        }
      }
      .post_wrap .post {
        .txt .post_header .post_meta {
          dt, dd {
            color: #999999;
          }
          dt a {
            color: #cccccc;
            &:link, &:visited {
              color: #cccccc;
            }
          }
          dd a {
            color: #cccccc;
            &:link, &:visited {
              color: #cccccc;
            }
          }
          dt a {
            &:hover, &:focus, &:active {
              color: #999999;
            }
          }
          dd a {
            &:hover, &:focus, &:active {
              color: #999999;
            }
          }
        }
        a {
          color: #ffffff;
          &:link, &:visited {
            color: #ffffff;
          }
          &:hover, &:focus, &:active {
            color: #999999;
          }
        }
      }
      .section_footer .more a {
        color: #777777;
        &:link, &:visited {
          color: #777777;
        }
        &:hover, &:focus, &:active {
          color: #aaaaaa;
        }
      }
      &.dot_line .post_wrap .post:before {
        background: linear-gradient(to right, #555, #555 60%, transparent 60%, transparent 100%);
        background-size: 5px 5px;
      }
    }
  }
}

@media #{$mobile} {
  #top #container {
    #featured .inner ul {
      &.single {
        width: 100%;
      }
      &.multiple li {
        display: block;
        width: 100%;
        max-width: none;
        padding: 0;
      }
    }
    #content {
      padding-top: 25px;
      border-top-width: 8px;
      .posts {
        padding-bottom: 25px;
        #main {
          float: none;
          width: auto;
        }
        #side {
          display: none;
        }
      }
    }
  }
}

.no-cssgradients #top #container #content .section.dot_line .post_wrap .post:before {
  background: none;
  border-top: 1px dashed #555555;
}

#top #container #content {
  .section.list {
    &.type_1 .post_wrap .post .txt h3 .label.sponsored {
      color: #666666;
      background-color: #fff200;
      &:before {
        border-right-color: #fff200;
      }
    }
    &.type_3 .post_wrap .post .rank {
      background-color: #262626;
      span {
        color: #ffffff;
      }
    }
  }
  #latest_snap {
    margin: 0 0 -50px;
    padding: 50px 0;
    background-color: #393939;
    border-top: 1px solid #555555;
    .inner {
      overflow: hidden;
      .section_body {
        position: relative;
        margin-bottom: 10px;
        .slide {
          overflow: hidden;
          .slide_wrap {
            margin-right: -25px;
            padding-right: 135px;
            .slide_list .post {
              padding-right: 25px;
              box-sizing: border-box;
              .photo a {
                img {
                  background-color: #000000;
                  transition: opacity 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
                  opacity: 1;
                }
                &:link img, &:visited img {
                  opacity: 1;
                }
                &:hover img, &:focus img, &:active img {
                  opacity: 0.6;
                }
              }
              .txt .post_meta {
                margin-top: 10px;
              }
            }
          }
        }
        .slide_nav {
          position: absolute;
          top: -47px;
          right: 0;
          width: 66px;
          margin-top: 0;
          .btn {
            position: absolute;
            top: 0;
            transition: background-color 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
            a {
              display: block;
              width: 26px;
              background-color: #999999;
              border-radius: 4px;
              &:before {
                content: ">";
                line-height: 26px;
                color: #404040;
                font-family: roc;
                font-size: 26px;
                font-size: 2.6rem;
              }
            }
            &.prev {
              left: 0;
              -webkit-transform: scale(-1);
              -ms-transform: scale(-1);
              transform: scale(-1);
            }
            &.next {
              right: 0;
            }
            &.over a {
              background-color: #cccccc;
            }
            &.disable a {
              background-color: #666666;
              cursor: default;
            }
          }
        }
      }
      .section_footer {
        margin-top: 20px;
      }
    }
  }
}

@media #{$mobile} {
  #top #container #content #latest_snap {
    margin: 0 0 -25px;
    padding: 25px 0;
    .inner {
      .section_body {
        .slide {
          margin-right: -10px;
          .slide_wrap {
            padding-right: 40px;
            .slide_list .post {
              padding-right: 10px;
              .txt .post_meta {
                margin-top: 5px;
                dt, dd {
                  font-size: 10px;
                  font-size: 1rem;
                }
              }
            }
          }
        }
        .slide_nav {
          display: none;
        }
      }
      .section_footer {
        margin-top: 10px;
      }
    }
  }
}


// search
// ==============================
.top_search {
  .module_search {
    background-color: #484848;
    border-top: 1px solid #555555;
    form {
      label.icon.search:before {
        color: #cccccc;
      }
      input {
        &[type=text], &[type=submit]:hover {
          color: #cccccc;
        }
      }
    }
  }
}


// latest
// ==============================
.top_latest__header {
  position: relative;

  a {
    color: #fff;
    &:link,
    &:visited {
      color: #fff;
    }
  }

}


.top_latest__header__title {
  text-align: center;
  font-size: 2rem;
  font-weight: 700;

  @media #{$mobile} {
    font-size: 1.5rem;
  }

}


.top_latest__header__more {
  position: absolute;
  right: 0;
  top: 0;

  a {
    font-size: 2rem;
    padding-right: 20px;

    @media #{$mobile} {
      font-size: 1.5rem;
    }

    &:link,
    &:visited {
      color: #58595c;
    }

    &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: "";
      vertical-align: middle;

      top: 0.2em;
      left: auto;
      right: 0px;
      box-sizing: border-box;
      width: 5px;
      height: 5px;
      border: 5px solid transparent;
      border-left: 5px solid #58595c;
    }

    &:hover {
      color: #fff;
      &:after {
        border-left-color: #fff;
      }
    }

  }

}



// special
// ==============================
.top_special {
  background: #000;
  padding: 24px 0;
  margin-top: 20px;
  line-height: 0;

  @media #{$mobile} {
    margin-top: 10px;
    padding: 12px 0;
  }
}

.top_special__innter {
  max-width:1260px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  @media #{$mobile} {
    padding: 0;
  }
}

.top_special__carousel {
  img {
    width: 100%;
    height: auto;
  }
}