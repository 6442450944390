@charset "utf-8";

/* contact.css */
#contact #container {
	display: block !important;
	.loader_wrap {
		display: none !important;
	}
}

#contact #container #content {
	padding-bottom: 0;
	section {
		padding-bottom: 50px;
		.inner {
			width: 640px;
		}
		.section_header {
			padding-bottom: 45px;
			text-align: center;
			background-color: #111111;
			.inner p {
				line-height: 1.6;
				color: #ffffff;
				font-size: 18px;
				font-size: 1.8rem;
				letter-spacing: -0.05em;
				+ p {
					margin-top: 30px;
				}
				span.note {
					display: block;
					color: #999999;
				}
			}
		}
		.section_body {
			padding-top: 38px;
			background-color: #ffffff;
			.inner {
				text-align: center;
				form dl {
					width: 100%;
					dt, dd {
						float: left;
						padding: 12px 0;
						vertical-align: top;
						text-align: left;
					}
					dt {
						width: 40%;
						font-size: 22px;
						font-size: 2.2rem;
					}
					dd {
						width: 60%;
						font-size: 22px;
						font-size: 2.2rem;
						input, select, textarea {
							width: 100%;
							margin-top: -12px;
							margin-left: -12px;
							padding: 10px;
							background-color: #ffffff;
							border: 1px solid #cccccc;
							border-radius: 0;
							-webkit-appearance: none;
							-moz-appearance: none;
							appearance: none;
							box-sizing: border-box;
						}
						select option {
							font-size: 16px;
							font-size: 1.6rem;
						}
						textarea {
							resize: none;
							height: 320px;
						}
					}
				}
			}
		}
	}
}

@media #{$mobile} {
	#contact #container #content section {
		padding-bottom: 25px;
		.inner {
			width: auto;
		}
		.section_header {
			padding-bottom: 20px;
			.inner p {
				font-size: 11px;
				font-size: 1.1rem;
				+ p {
					margin-top: 15px;
				}
			}
		}
		.section_body {
			padding-top: 19px;
			.inner form dl {
				dt, dd {
					padding: 6px 0;
				}
				dt {
					font-size: 11px;
					font-size: 1.1rem;
				}
				dd {
					font-size: 11px;
					font-size: 1.1rem;
					textarea {
						height: 160px;
					}
					input, select, textarea {
						margin-top: -6px;
						margin-left: -6px;
						padding: 5px;
					}
				}
			}
		}
	}
}

.appearance #contact #container #content section .section_body .inner form dl dd .select_group {
	position: relative;
	&:after {
		content: "";
		display: block;
		top: 50%;
		right: 10px;
		position: absolute;
		width: 13px;
		height: 20px;
		margin-top: -12px;
		background: url("../images/contact/arrow.png") no-repeat 0 0;
		background-size: auto 100%;
		z-index: 20;
	}
}

@media #{$mobile} {
	.appearance #contact #container #content section .section_body .inner form dl dd .select_group:after {
		right: 5px;
		width: 7px;
		height: 10px;
		margin-top: -5px;
	}
}

#contact #container #content section .section_body .inner form .btn {
	display: inline-block;
	margin: 46px auto 0;
	li {
		display: table-cell;
		padding: 0 20px;
		font-size: 22px;
		font-size: 2.2rem;
		border-right: 1px solid #cccccc;
		input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			padding: 0;
			line-height: 1;
			background: none;
			border: 0 none;
			cursor: pointer;
			&[type=reset] {
				color: #999999;
			}
			&:hover {
				color: #666666;
			}
		}
	}
}

@media #{$mobile} {
	#contact #container #content section .section_body .inner form .btn {
		margin-top: 20px;
		li {
			padding: 0 10px;
			font-size: 11px;
			font-size: 1.1rem;
		}
	}
}

.csstransitions #contact #container #content section .section_body .inner form .btn li input {
	transition: color 240ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

#contact #container #content section {
	.section_body .inner form .btn li:last-child {
		border-right: 0 none;
	}
	&.thanks {
		padding-bottom: 0;
		.section_header .inner {
			padding-top: 80px;
			background: url("../images/contact/icon_mail.png") no-repeat center top;
			background-size: 98px auto;
			p span.bold {
				display: block;
				font-weight: bold;
			}
			.btn {
				margin-top: 40px;
				font-size: 22px;
				font-size: 2.2rem;
			}
		}
	}
}

@media #{$mobile} {
	#contact #container #content section.thanks .section_header .inner {
		padding-top: 40px;
		background-size: 49px auto;
		.btn {
			margin-top: 20px;
			font-size: 11px;
			font-size: 1.1rem;
		}
	}
}
