@charset "utf-8";

/* header.css */

#container {
	#header {
		padding: 33px 0;

		.inner {
			position: relative;

			#btn_menu {
				position: absolute;
				left: 20px;
				top: -4px;
				width: 46px;
				height: 46px;
				cursor: pointer;
				ul {
					position: relative;
					width: 100%;
					height: 100%;
					border: 2px solid #666666;
					border-radius: 50%;
					box-sizing: border-box;
					li {
						position: absolute;
						top: 50%;
						left: 50%;
						width: 24px;
						height: 3px;
						margin-left: -12px;
						background-color: #ffffff;
						border-radius: 1px;
						&:nth-child(1) {
							top: 12px;
						}
						&:nth-child(2) {
							top: 20px;
						}
						&:nth-child(3) {
							top: 28px;
						}
					}
				}
			}

			h1 {
				width: 270px;
				text-align: center;
				margin: 0 auto;
			}

			#lang {
				position: absolute;
				right: 20px;
				top: 0;
				margin-top: 10px;
				li {
					display: table-cell;
					padding: 0 10px;
					line-height: 1;
					font-size: 18px;
					font-size: 1.8rem;
					&:first-child {
						border-right: 1px solid #666666;
					}
					a {
						color: #999999;
						&:link, &:visited {
							color: #999999;
						}
						&:hover, &:focus, &:active {
							color: #ffffff;
						}
					}
					&.active a {
						color: #ffffff;
						font-weight: normal;
					}
				}
			}

		}
	}

	#overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: transparent;
		z-index: 1000;
		.overlay_bg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.9);
			z-index: 10;
		}
		#overlay_container {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 20;
			overflow-x: auto;
			overflow-y: scroll;
			-webkit-overflow-scrolling: touch;
			.btn_wrap {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				padding-top: 30px;
			}
			.btn.close {
				cursor: pointer;
				&.large {
					float: right;
					width: 46px;
					height: 46px;
					padding: 0;
					ul {
						position: relative;
						width: 100%;
						height: 100%;
						border: 2px solid #666666;
						border-radius: 50%;
						box-sizing: border-box;
						li {
							position: absolute;
							top: 50%;
							left: 50%;
							width: 30px;
							height: 3px;
							margin-top: -1px;
							margin-left: -15px;
							background-color: #ffffff;
							border-radius: 1px;
							&:nth-child(1) {
								-webkit-transform: rotate(45deg);
								-ms-transform: rotate(45deg);
								transform: rotate(45deg);
							}
							&:nth-child(2) {
								-webkit-transform: rotate(-45deg);
								-ms-transform: rotate(-45deg);
								transform: rotate(-45deg);
							}
						}
					}
				}
			}
			> .inner {
				width: 640px;
				padding: 92px 0 0;
				text-align: center;
				.wrap {
					width: 640px;
					margin: 45px auto 0;
					padding-bottom: 50px;
					.overlay_header h1 {
						color: #999999;
						font-size: 18px;
						font-size: 1.8rem;
					}
					nav ul {
						&.global li {
							margin-top: 40px;
							line-height: 1;
							font-size: 38px;
							font-size: 3.8rem;
							a {
								color: #ffffff;
								&:link, &:visited {
									color: #ffffff;
								}
								&:hover, &:focus, &:active {
									color: #999999;
								}
							}
						}
						&.sns {
							display: inline-block;
							margin-top: 50px;
							li {
								display: table-cell;
								width: 26px;
								padding: 0 15px;
							}
						}
					}
					.module_search {
						margin-top: 50px;
						background-color: #ffffff;
						form input[type=text] {
							width: 400px;
						}
					}
					.overlay_footer {
						margin-top: 100px;
						h2 {
							color: #999999;
							font-size: 18px;
							font-size: 1.8rem;
						}
						ul {
							margin-top: 35px;
							li {
								margin-top: 20px;
							}
						}
						.btn.close {
							display: inline-block;
							position: relative;
							margin: 45px auto 0;
							padding-left: 30px;
							color: #999999;
							font-size: 18px;
							font-size: 1.8rem;
							&:before {
								content: "x";
								position: absolute;
								top: 50%;
								left: 0;
								margin-top: -11px;
								line-height: 1;
								font-family: roc;
								font-size: 22px;
								font-size: 2.2rem;
							}
						}
					}
				}
			}
		}
	}
}

@media #{$mobile} {
	#container {
		#header {
			padding: 22px 0;
			.inner {
				h1 {
					width: 152px;
				}
				#lang {
					margin-top: 5px;
					li {
						padding: 0 5px;
						font-size: 9px;
						font-size: 0.9rem;
					}
				}
				#btn_menu {
					top: -1px;
					right: 10px;
					width: 24px;
					height: 24px;
					ul {
						border-width: 1px;
						li {
							width: 12px;
							height: 1px;
							margin-left: -6px;
							&:nth-child(1) {
								top: 6px;
							}
							&:nth-child(2) {
								top: 10px;
							}
							&:nth-child(3) {
								top: 14px;
							}
						}
					}
				}
			}
		}
		#overlay #overlay_container {
			.btn_wrap {
				padding-top: 22px;
			}
			.btn.close.large {
				width: 24px;
				height: 24px;
				ul {
					border-width: 1px;
					li {
						width: 14px;
						height: 1px;
						margin-top: 0;
						margin-left: -7px;
					}
				}
			}
			> .inner {
				width: 100%;
				padding: 45px 0 0;
				.wrap {
					width: 100%;
					margin-top: 20px;
					padding-top: 0;
					.overlay_header h1 {
						font-size: 9px;
						font-size: 0.9rem;
					}
					nav ul {
						&.global li {
							margin-top: 20px;
							font-size: 19px;
							font-size: 1.9rem;
						}
						&.sns {
							margin-top: 25px;
							li {
								width: 13px;
								padding: 0 8px;
							}
						}
					}
					.module_search form input[type=text] {
						width: 75%;
					}
					.overlay_footer {
						margin-top: 50px;
						padding: 0 10px;
						h2 {
							font-size: 9px;
							font-size: 0.9rem;
						}
						ul {
							margin-top: 18px;
							li {
								margin-top: 10px;
							}
						}
						.btn.close {
							margin-top: 22px;
							padding-left: 15px;
							font-size: 9px;
							font-size: 0.9rem;
							&:before {
								margin-top: -5px;
								font-size: 11px;
								font-size: 1.1rem;
							}
						}
					}
				}
			}
		}
	}
}

