@charset "utf-8";

/* archives.css */
#archives #container {
	display: block !important;
	.loader_wrap {
		display: none !important;
	}
}

#archives #container #content.index {
	padding-top: 0;
	background-color: #111111;
	.inner #categories .module_categories .category {
		margin-bottom: 25px;
		text-align: center;
		.img {
			img {
				width: 100%;
				height: auto;
			}
		}
		.wrap .icon {
			background-color: #111111;
		}
		.date {
			margin-top: 10px;
			color: #999999;
			font-family: "aktiv-grotesk", HelveticaNeue, Helvetica, Roboto, "Droid Sans", sans-serif;
			font-size: 12px;
			font-size: 1.2rem;
		}
	}
}

@media #{$mobile} {
	#archives #container #content.index .inner {
		padding: 0;
		#categories .module_categories .category .date {
			font-size: 9px;
			font-size: 0.9rem;
		}
		#members {
			padding: 0 10px;
			box-sizing: border-box;
		}
	}
}



// oembed
// ==============================
.wp-embedded-content {
	width:100%;
}
